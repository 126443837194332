import type { ReactElement } from 'react';

import {
  type CustomTypographyProps,
  Typography,
} from '../Typography/Typography';

export function Text(
  props: CustomTypographyProps<'span' | 'div' | 'label' | 'p'>
): ReactElement {
  return <Typography {...props} />;
}

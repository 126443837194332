import { useEffect, type ReactElement, useRef } from 'react';

import styles from './Results.module.scss';

export function Results({
  resultLink,
  resultType,
}: {
  resultLink: string;
  resultType: 'video' | 'audio';
}): ReactElement {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current === null) return;

    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [resultLink]);

  return (
    <div className={styles.results} ref={ref}>
      <figure>
        {resultType === 'audio' && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio controls src={resultLink} className={styles.results__audio} />
        )}

        {resultType === 'video' && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video controls src={resultLink} className={styles.results__video} />
        )}
      </figure>
    </div>
  );
}

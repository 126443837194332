import { fetchBaseQuery } from '@reduxjs/toolkit/query';

// import { auth } from './firebase';
// import { type RootState } from './store';

export const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_API_BASE_URL}/api`,
  headers: {},
  // prepareHeaders: async (headers, { getState }) => {
  // const user = (getState() as RootState).auth.user;
  // const firebaseUser = auth.currentUser;

  // if (user !== null && firebaseUser !== null) {
  //   const idToken = await firebaseUser.getIdToken();

  //   headers.set('X_USER_ID', user.uid);
  //   headers.set('X_TOKEN', idToken);
  //   headers.set('X_PLATFORM', 'web');
  // }

  //   return headers;
  // },
});

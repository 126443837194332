import { api } from '@/providers/api';
import { z } from 'zod';

const GenerateResponseSchema = z.object({
  link: z.string(),
  type: z.enum(['video', 'audio']),
});

type GenerateResponseSchemaType = z.infer<typeof GenerateResponseSchema>;

const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generateMedia: builder.mutation<GenerateResponseSchemaType, FormData>({
      query: (formData) => ({
        url: 'https://api.clonix.app/cartoonize',
        method: 'POST',
        body: formData,
        responseHandler: async (response) =>
          await GenerateResponseSchema.parseAsync(await response.json()),
      }),
    }),
  }),
});

export const { useGenerateMediaMutation } = productsApi;

import { type ReactElement, Suspense, useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './PublicLayout.module.scss';
import { Header } from '@/features/layouts';
import cn from 'clsx';

export function PublicLayout(): ReactElement {
  const [isBuyRoute, setIsBuyRoute] = useState('false');
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/buy') {
      setIsBuyRoute('true');
    }
  }, [location.pathname]);
  return (
    <div
      className={cn(styles['public-layout'], {
        [styles['public-layout--buy']]: isBuyRoute === 'true',
      })}
    >
      <Header />

      <main className={styles.main}>
        <div className={styles.main__container}>
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </main>
    </div>
  );
}

import { useState, type ReactElement } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';

import { auth } from '@/providers/firebase';
import { Button, Form, InlineLink, TextField } from '@/components';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { FormTitle } from '../../components/FormTitle/FormTitle';
import styles from './ForgotPasswordForm.module.scss';

interface ForgotPasswordFormType {
  email: string;
}

export function ForgotPasswordForm(): ReactElement {
  const [emailSent, setEmailSent] = useState(false);
  const [sendPasswordResetEmail, loading, error] =
    useSendPasswordResetEmail(auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormType>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .nonempty("Email can't be empty.")
          .email('Email is invalid'),
      })
    ),
  });

  const handleForgotPassword: SubmitHandler<ForgotPasswordFormType> = async ({
    email,
  }) => {
    const success = await sendPasswordResetEmail(email);

    setEmailSent(success);
  };

  return (
    <div className={styles['forgot-password-form']}>
      <FormTitle>Forgot Password</FormTitle>

      {emailSent && (
        <p>Check your email for instructions to reset your password.</p>
      )}

      {!emailSent && (
        <Form
          className={styles.form}
          onSubmit={handlePromiseEvent(handleSubmit(handleForgotPassword))}
        >
          <TextField
            type="email"
            placeholder="Email"
            errorMessage={errors.email?.message}
            {...register('email')}
          />

          {/* TODO: We need to handle error messages. */}
          {error !== undefined && <p>{error.message}</p>}

          <Button variant="primary" loading={loading} type="submit">
            Continue
          </Button>
        </Form>
      )}

      <div className={styles['login-info']}>
        <InlineLink href="/login">Back to Login</InlineLink>
      </div>
    </div>
  );
}

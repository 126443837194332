import { type ReactElement } from 'react';

import { GoogleLogin } from './GoogleLogin/GoogleLogin';
import styles from './SocialLogin.module.scss';

export function SocialLogin(): ReactElement {
  return (
    <div className={styles['social-login']}>
      <GoogleLogin />
    </div>
  );
}

import { type ReactElement } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import 'highlight.js/styles/github.css';
import * as Sentry from '@sentry/react';

import { persistor, store } from '@/providers/store';
import { AuthLayout, PublicLayout, UserLayout } from '@/layouts';
import { initI18next } from '@/providers/i18n';
import { useAuth } from '@/hooks/auth';
import '@/providers/sentry';

// Styles, order is important.
import '@/styles/fonts.css';
import '@/styles/sizes.scss';
import '@/styles/borders.css';
import '@/styles/colors.css';
import '@/styles/globals.css';
import '@/styles/reset.css';
import '@/styles/media.css';
import '@/styles/animations.css';
import '@/styles/zindex.css';
import '@/styles/shadows.css';

import Login from '@/routes/login';
import Register from '@/routes/register';
import ForgotPassword from '@/routes/forgot-password';
import NotFound from '@/routes/404';
import Index from '@/routes/index';
import Onboarding from '@/routes/onboarding';
import Buy from '@/routes/buy';

// TODO: Pages needs to lazy-loaded like below with a spinner fallback.
// const Login = lazy(async () => await import('@/routes/login'));

export const App = (): ReactElement => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
            onBeforeLift={async () => {
              await initI18next();
            }}
          >
            <AppRoutes />
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

const RoutesWithSentryInstrumentation =
  Sentry.withSentryReactRouterV6Routing(Routes);

function AppRoutes(): ReactElement {
  useAuth();

  return (
    <RoutesWithSentryInstrumentation>
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>

      <Route element={<UserLayout />}>
        <Route path="/" element={<Index />} />
      </Route>

      <Route element={<PublicLayout />}>
        <Route path="*" element={<NotFound />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/buy" element={<Buy />} />
      </Route>
    </RoutesWithSentryInstrumentation>
  );
}

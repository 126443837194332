import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { HttpClient } from '@sentry/integrations';

if (
  !import.meta.env.DEV &&
  typeof import.meta.env.VITE_VERCEL_ENV === 'string'
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/.*\.novaapp\.ai\/api/,
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
      new HttpClient(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate:
      import.meta.env.VITE_VERCEL_ENV === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_VERCEL_ENV,
  });
}

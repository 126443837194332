import { type ReactElement, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';
import { Header } from '@/features/layouts';

import styles from './UserLayout.module.scss';

export function UserLayout(): ReactElement {
  const auth = useAppSelector(selectAuth);
  const location = useLocation();

  const loggedIn = auth.user !== null;

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <div className={styles['user-layout']}>
      <Header />

      <main className={styles.main}>
        <div className={styles.main__container}>
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </main>
    </div>
  );
}

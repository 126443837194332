import type { ReactElement } from 'react';

import styles from './FieldLabel.module.scss';

export function FieldLabel({
  children,
  htmlFor,
}: {
  children: string;
  htmlFor?: string;
}): ReactElement {
  return (
    <label className={styles['field-label']} htmlFor={htmlFor}>
      {children}
    </label>
  );
}

import { type ReactElement } from 'react';
import cn from 'clsx';
import { type IconDefinition, faCheck } from '@fortawesome/pro-solid-svg-icons';

import { Icon } from '@/components';

import styles from './SelectItem.module.scss';

export function SelectItem({
  layout,
  type,
  label,
  checked,
  groupName,
  icon,
  onChange,
}: {
  layout: 'list' | 'grid' | 'wrap';
  type: 'radio' | 'checkbox';
  label: string;
  groupName: string;
  checked: boolean;
  icon?: IconDefinition;
  onChange: () => void;
}): ReactElement {
  return (
    <label
      className={cn(styles['select-item'], {
        [styles['select-item--active']]: checked,
      })}
    >
      <input
        type={type}
        value={label}
        name={groupName}
        onChange={() => {
          onChange();
        }}
        checked={checked}
        className={styles['select-item__input']}
      />

      <div
        className={cn(styles['select-item__content'], {
          [styles[`select-item__content--center-aligned`]]: layout === 'grid',
        })}
      >
        {icon !== undefined && (
          <Icon
            icon={icon}
            className={styles['select-item__icon']}
            fixedWidth
            size="lg"
          />
        )}

        <span className={styles['select-item__text']}>{label}</span>

        {checked && type === 'checkbox' && layout !== 'wrap' && (
          <div className={styles['select-item__checked-icon']}>
            {checked && <Icon icon={faCheck} size="lg" />}
          </div>
        )}
      </div>
    </label>
  );
}

import type { ReactElement } from 'react';
import cn from 'clsx';

import styles from './SectionLabel.module.scss';

export function SectionLabel({
  children,
  htmlFor,
  className,
}: {
  children: string;
  htmlFor?: string;
  className?: string;
}): ReactElement {
  return (
    <label className={cn(styles['section-label'], className)} htmlFor={htmlFor}>
      {children}
    </label>
  );
}

import { Icon } from '@/components';
import {
  faCheck,
  faPlus,
  // faUpload,
} from '@fortawesome/pro-solid-svg-icons';
import cn from 'clsx';
import {
  useRef,
  type ReactElement,
  type ChangeEventHandler,
  type FocusEventHandler,
  type ForwardedRef,
} from 'react';

import styles from './AddYourVoice.module.scss';

export function AddYourVoice({
  selectedFile,
  onSelectFile,
  groupName,
  inputRef,
  onChange,
  onBlur,
}: {
  groupName: string;
  inputRef?: ForwardedRef<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  selectedFile: File | null;
  onSelectFile: (file: File) => void;
}): ReactElement {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const hasSelectedFile = selectedFile !== null;

  return (
    <label
      className={cn(styles['add-your-voice'], {
        [styles['add-your-voice--enabled']]: hasSelectedFile,
      })}
    >
      <input
        ref={inputRef}
        type="radio"
        name={groupName}
        value="your-voice"
        onChange={onChange}
        onBlur={onBlur}
        className={styles['add-your-voice__radio']}
        disabled={!hasSelectedFile}
        hidden
      />

      <button
        onClick={() => {
          fileInputRef?.current?.click();
        }}
        type="button"
        className={styles['add-your-voice__button']}
      >
        {hasSelectedFile ? (
          <Icon
            icon={faCheck}
            className={styles['add-your-voice__button-icon']}
          />
        ) : (
          <Icon
            icon={faPlus}
            className={styles['add-your-voice__button-icon']}
          />
        )}

        {/* <Icon
          icon={faUpload}
          className={styles['add-your-voice__button-select-icon']}
        /> */}
      </button>

      <input
        ref={fileInputRef}
        onChange={(event) => {
          const file = event.target.files?.[0];

          if (file !== undefined) {
            onSelectFile(file);
          }

          if (fileInputRef.current !== null) {
            fileInputRef.current.value = '';
          }
        }}
        type="file"
        accept="audio/mp3"
        hidden
      />

      <div className={styles['add-your-voice__name']}>Your Voice</div>
    </label>
  );
}

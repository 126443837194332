import {
  combineReducers,
  configureStore,
  type AnyAction,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import createMigrate from 'redux-persist/es/createMigrate';

import { api } from '@/providers/api';
import { authReducer } from '@/features/auth';
import { settingsReducer } from '@/features/settings';

const appReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  [api.reducerPath]: api.reducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === 'auth/logOut') {
    // eslint-disable-next-line no-console
    storage.removeItem('persist:root').catch(console.error);
    return appReducer(undefined, { type: undefined });
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    version: 3,
    whitelist: ['auth', 'settings'],
    migrate: createMigrate(
      {
        // @ts-expect-error Provided typings are wrong.
        3: (state) => ({
          ...state,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          settings: {
            // @ts-expect-error Provided typings are wrong.
            ...state.settings,
            drawer: 'opened',
          },
        }),
      },
      { debug: import.meta.env.DEV }
    ),
  },
  rootReducer
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
const middlewareHandler = (getDefaultMiddleware: any) => {
  const middlewareList = [
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    ...getDefaultMiddleware({
      serializableCheck: {
        // NOTE: Ignore all the action types redux-resist dispatches to prevent non-serializable value error
        // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    createStateSyncMiddleware({
      whitelist: ['auth/logOut', 'auth/setUser'],
    }),
    api.middleware,
  ];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return middlewareList;
};

export const store = configureStore({
  reducer: persistedReducer,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
});

initMessageListener(store);
setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { type ReactElement } from 'react';

import styles from './Features.module.scss';
import { Icon } from '@/components';
import { faInfinity, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import {
  faLock,
  type IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';

const features: Array<{
  icon: IconDefinition;
  title: string;
  description: string;
}> = [
  {
    icon: faSparkles,
    title: 'Powered by CloniX Tech',
    description: 'Access to all voices and languages',
  },
  {
    icon: faInfinity,
    title: 'Unlimited characters per year',
    description: 'Unlimited re-generations',
  },
  {
    icon: faLock,
    title: 'No Ads, faster generations',
    description: 'High-Fidelity voice clones',
  },
];

export function Features(): ReactElement {
  return (
    <ul className={styles.features}>
      {features.map(({ icon, title, description }) => (
        <li key={title} className={styles.feature}>
          <Icon icon={icon} className={styles.feature__image} />
          <div>
            <p className={styles.feature__title}>{title}</p>
            <p className={styles.feature__description}>{description}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}

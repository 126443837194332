import { type ReactElement } from 'react';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';

import { auth } from '@/providers/firebase';
import { Button } from '@/components';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

export function GoogleLogin(): ReactElement {
  const [signInWithGoogle] = useSignInWithGoogle(auth);

  const handleLogin = async (): Promise<void> => {
    await signInWithGoogle();
  };

  return (
    <Button
      variant="outlined"
      type="button"
      onClick={handleLogin}
      startIcon={faGoogle}
    >
      Continue with Google
    </Button>
  );
}

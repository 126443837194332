import { Icon } from '@/components';
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import {
  useRef,
  type ReactElement,
  useState,
  type ChangeEventHandler,
  type FocusEventHandler,
  type ForwardedRef,
} from 'react';

import styles from './VoiceStyleItem.module.scss';

export function VoiceStyleItem({
  id,
  name,
  image,
  voice,
  groupName,
  inputRef,
  onChange,
  onBlur,
}: {
  id: string;
  name: string;
  image: string;
  voice: string;
  groupName: string;
  inputRef?: ForwardedRef<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}): ReactElement {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [playing, setPlaying] = useState(false);

  const handlePlayPauseClick = (): void => {
    if (audioRef.current !== null) {
      if (playing) {
        audioRef.current.pause();
      } else {
        const containerEl =
          audioRef.current.parentElement?.parentElement?.parentElement;

        containerEl?.querySelectorAll('audio').forEach((el) => {
          el.pause();
        });

        audioRef.current.currentTime = 0;
        // eslint-disable-next-line no-console
        audioRef.current.play().catch(console.error);
      }
    }
  };

  return (
    <label className={styles['voice-style-item']}>
      <input
        ref={inputRef}
        type="radio"
        name={groupName}
        value={id}
        onChange={onChange}
        onBlur={onBlur}
        className={styles['voice-style-item__radio']}
        hidden
      />

      <figure className={styles['voice-style-item__figure']}>
        <div className={styles['voice-style-item__wrapper']}>
          <img
            src={image}
            alt=""
            height={100}
            width={100}
            className={styles['voice-style-item__image']}
          />

          <button
            onClick={handlePlayPauseClick}
            type="button"
            className={styles['voice-style-item__play']}
          >
            {playing ? (
              <Icon
                icon={faPause}
                className={styles['voice-style-item__play-icon']}
              />
            ) : (
              <Icon
                icon={faPlay}
                className={styles['voice-style-item__play-icon']}
              />
            )}
          </button>
        </div>

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          ref={audioRef}
          controls
          src={voice}
          hidden
          onPlay={() => {
            setPlaying(true);
          }}
          onPause={() => {
            setPlaying(false);
          }}
          tabIndex={-1}
        />

        <figcaption className={styles['voice-style-item__name']}>
          {name}
        </figcaption>
      </figure>
    </label>
  );
}

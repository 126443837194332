import cemOrtabasImage from '@/assets/voice-styles/cem-ortabas.png';
import denizTunaImage from '@/assets/voice-styles/deniz-tuna.png';
import elonMuskImage from '@/assets/voice-styles/elon-musk.png';
import joanneImage from '@/assets/voice-styles/joanne.png';
import marcusImage from '@/assets/voice-styles/marcus.png';
import michealImage from '@/assets/voice-styles/micheal.png';
import morganFreemanImage from '@/assets/voice-styles/morgan-freeman.png';
import obamaImage from '@/assets/voice-styles/obama.png';
import ozgeZauggImage from '@/assets/voice-styles/ozge-zaugg.png';
import ryanKurkImage from '@/assets/voice-styles/ryan-kurk.png';
import sallyImage from '@/assets/voice-styles/sally.png';
import snopDoggImage from '@/assets/voice-styles/snop-dogg.png';
import valentinoImage from '@/assets/voice-styles/valentino.png';
import danDanImage from '@/assets/voice-styles/dan-dan.png';

export interface VoiceStyle {
  id: string;
  name: string;
  image: string;
  voice: string;
}

export const voiceStyles: VoiceStyle[] = [
  {
    id: '8MNiZPWrUZ7PjeBZ3Ubw',
    name: 'Cem Ortabas',
    image: cemOrtabasImage,
    voice: 'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Cem+Ortabas.mp3',
  },
  {
    id: 'UaKAR76qaWu1sMJIFvdQ',
    name: 'Deniz Tuna',
    image: denizTunaImage,
    voice: 'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Deniz+Tuna.mp3',
  },
  {
    id: 'wdQ7034mLKzSn1uQ2NDo',
    name: 'Ozge Zaugg',
    image: ozgeZauggImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/wiser-covers/ElevenLabs_2023-11-04T13_06_28_O%CC%88zge+New_ivc_s50_sb75_se0_b_m2.mp3',
  },
  {
    id: 'rrVhFT0hM9RJHWKgc5HM',
    name: 'Snop Dogg',
    image: snopDoggImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Snoop+Dogg+Final.mp3',
  },
  {
    id: 'uuyq868vhf2ToV39Ch06',
    name: 'Elon Musk',
    image: elonMuskImage,
    voice: 'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Elon+Musk.mp3',
  },
  {
    id: 'wfqBTrICnIcjzt1ivV9Q',
    name: 'Obama',
    image: obamaImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/ElevenLabs_2023-11-03T18_55_03_Obama_ivc_s50_sb75_se0_b_m2.mp3',
  },
  {
    id: 'qsjVucCOtu1B97VGICU3',
    name: 'Morgan Freeman',
    image: morganFreemanImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Morgan+Freeman.mp3',
  },
  {
    id: '9F4C8ztpNUmXkdDDbz3J',
    name: 'Dan Dan',
    image: danDanImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/voice_preview_Dan+Dan.mp3',
  },
  {
    id: 'F2wRoop8sFVqRJnkTW4O',
    name: 'Joanne',
    image: joanneImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/voice_preview_Joanne+-+pensive%2C+introspective%2C+soft+and+lovely.mp3',
  },
  {
    id: '83nQdLZMhO0vVsQTBY19',
    name: 'Marcus',
    image: marcusImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/voice_preview_Marcus+-+authoritative+and+deep.mp3',
  },
  {
    id: 'qdmLpreqWwCVjXgNFGQa',
    name: 'Michael',
    image: michealImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/voice_preview_Michael+-+Deep%2C+Resonant%2C+Confident.mp3',
  },
  {
    id: 'rU18Fk3uSDhmg5Xh41o4',
    name: 'Ryan Kurk',
    image: ryanKurkImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/voice_preview_Ryan+Kurk.mp3',
  },
  {
    id: 'UbkpIT1IQsk0ED8tyVef',
    name: 'Sally',
    image: sallyImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/voice_preview_Sally+-+very+realistic%2C+superb.mp3',
  },
  {
    id: '69dd0VFNUtEN5KUy9WtO',
    name: 'Valentino',
    image: valentinoImage,
    voice:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/voice_preview_Valentino.mp3',
  },
];

'use client';

import { type ReactElement, Suspense, useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';
import { Header } from '@/features/layouts';
import cn from 'clsx';
import styles from './AuthLayout.module.scss';

interface NavigationState {
  from?: string;
}

type LocationState = NavigationState | undefined;

export function AuthLayout(): ReactElement {
  const [isBuyRoute, setIsBuyRoute] = useState('false');

  const auth = useAppSelector(selectAuth);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/buy') {
      setIsBuyRoute('true');
    }
  }, [location.pathname]);

  const loggedIn = auth.user !== null;

  const navigationState = location.state as LocationState;

  if (loggedIn) {
    return <Navigate to={navigationState?.from ?? `/buy`} replace />;
  }

  return (
    <div
      className={cn(styles['auth-layout'], {
        [styles['auth-layout--buy']]: isBuyRoute === 'true',
      })}
    >
      <Header />

      <main className={styles.main}>
        <div className={styles.main__container}>
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </main>
    </div>
  );
}

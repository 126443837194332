import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type RootState } from '@/providers/store';
import { type UserType } from '../types/user';

interface AuthStore {
  user: UserType | null;
}

const initialState: AuthStore = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<NonNullable<AuthStore['user']>>) {
      state.user = payload;
    },
    logOut() {
      // This is handled by `rootReducer`.
    },
  },
});

export const { setUser, logOut } = authSlice.actions;
export const authReducer = authSlice.reducer;

export const selectAuth = (state: RootState): AuthStore => state.auth;

import type { ReactElement } from 'react';

import cn from 'clsx';
import styles from './Circles.module.scss';

export function Circles({ active }: { active: number }): ReactElement {
  const circles = Array.from({ length: 4 }, (_, index) => index);

  return (
    <div className={styles.circles}>
      {circles.map((_, index) => (
        <div
          key={index}
          className={cn(styles.circle, {
            [styles['circle--active']]: index === active,
          })}
        />
      ))}
    </div>
  );
}

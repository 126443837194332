import {
  forwardRef,
  type ForwardedRef,
  type ReactElement,
  type ButtonHTMLAttributes,
  type HTMLAttributeAnchorTarget,
} from 'react';
import cn from 'clsx';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';

import styles from './Button.module.scss';
import { Spinner } from './Spinner/Spinner';
import { NavLink } from 'react-router-dom';
import { Icon } from '../Icon/Icon';

interface ButtonElementProps {
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  target?: never;
  href?: never;
}

interface AnchorElementProps {
  type: 'link';
  target?: HTMLAttributeAnchorTarget;
  href: string;
}

type ButtonProps = {
  variant: 'primary' | 'transparent' | 'outlined' | 'vivid';
  size?: 'small' | 'large';
  children: string | ReactElement;
  onClick?: () => Promise<void> | void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  startIcon?: IconProp;
  endIcon?: IconProp;
} & (ButtonElementProps | AnchorElementProps);

function ButtonComponent(
  {
    size = 'large',
    variant,
    children,
    href,
    target,
    type = 'button',
    className,
    onClick,
    loading = false,
    disabled = false,
    startIcon,
    endIcon,
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement> | ForwardedRef<HTMLAnchorElement>
): ReactElement {
  const buttonProps = {
    onClick,
    className: cn(
      styles.button,
      styles[`button--variant-${variant}`],
      styles[`button--size-${size}`],
      {
        [styles['button--loading']]: loading,
        [styles['button--disabled']]: disabled ?? loading,
      },
      className
    ),
  };

  const buttonContent = (
    <>
      <div className={styles.button__content}>
        {startIcon !== undefined && <Icon icon={startIcon} />}

        <span className={styles.button__children}>{children}</span>

        {endIcon !== undefined && <Icon icon={endIcon} />}
      </div>

      <Spinner active={loading} />
    </>
  );

  if (type === 'link') {
    return (
      <NavLink
        target={target}
        to={href as string}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        {...buttonProps}
      >
        {buttonContent}
      </NavLink>
    );
  }

  return (
    <button
      ref={ref as ForwardedRef<HTMLButtonElement>}
      type={type}
      disabled={loading || disabled}
      {...buttonProps}
    >
      {buttonContent}
    </button>
  );
}

export const Button = forwardRef(ButtonComponent);

import { Button, Heading, Text } from '@/components';
import { useState, type ReactElement } from 'react';

import styles from './OnboardingThird.module.scss';
import { CheckboxGroup } from '../../components/CheckboxGroup/CheckboxGroup';
import { Circles } from '../../components/Circles/Circles';

const onboardingAnswers = [
  {
    label: 'Corporate',
    value: 'corporate',
  },
  {
    label: 'Operations',
    value: 'operations',
  },
  {
    label: 'Student',
    value: 'student',
  },
  {
    label: 'Mentoring',
    value: 'mentoring',
  },
  {
    label: 'Content Creation',
    value: 'content-creation',
  },
  {
    label: 'Others/Personel',
    value: 'others-personel',
  },
  {
    label: 'Podcaster',
    value: 'podcaster',
  },
  {
    label: 'Finance',
    value: 'finance',
  },
  {
    label: 'Author',
    value: 'author',
  },
  {
    label: 'Teacher',
    value: 'teacher',
  },
] as const;

export function OnboardingThird(): ReactElement {
  const [answers, setAnswers] = useState<
    Array<(typeof onboardingAnswers)[number]['value']>
  >([]);

  return (
    <div className={styles.onboarding}>
      <Heading
        className={styles.title}
        as="h2"
        size={{
          initial: '6',
        }}
        weight="bold"
      >
        What do you do for work?
      </Heading>

      <Text
        className={styles.subtitle}
        as="p"
        size={{
          initial: '3',
        }}
        weight="medium"
      >
        This will help us personolize your experience
      </Text>

      <div className={styles['question-content']}>
        <CheckboxGroup
          layout="wrap"
          value={answers}
          segments={onboardingAnswers}
          onChange={(places) => {
            setAnswers(places);
          }}
        />
      </div>

      <Circles active={2} />

      <Button
        type="link"
        href="/onboarding?step=4"
        variant="primary"
        className={styles['next-button']}
      >
        Next
      </Button>
    </div>
  );
}

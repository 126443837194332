import { type ReactNode, type ReactElement } from 'react';
import cn from 'clsx';

import styles from './Badge.module.scss';

export function Badge({
  type,
  children,
}: {
  type: 'save';
  children: ReactNode;
}): ReactElement {
  return (
    <span
      className={cn(styles.badge, {
        [styles['badge--save']]: type === 'save',
      })}
    >
      {children}
    </span>
  );
}

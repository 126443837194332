import {
  type ChangeEventHandler,
  type FocusEventHandler,
  type ForwardedRef,
  type ReactElement,
  forwardRef,
  useId,
} from 'react';
import cn from 'clsx';

import { FieldLabel } from './FieldLabel/FieldLabel';

import styles from './TextAreaField.module.scss';

interface TextareaFieldProps {
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  value?: string;
  className?: string;
  name?: string;
  label?: string;
  errorMessage?: string;
  rows?: number;
  maxLength?: number;
}

function TextAreaFieldComponent(
  {
    name,
    value,
    onChange,
    placeholder,
    className,
    onBlur,
    label,
    maxLength,
    rows,
  }: TextareaFieldProps,
  forwardedRef: ForwardedRef<HTMLTextAreaElement>
): ReactElement {
  const id = useId();

  return (
    <div className={cn(styles['text-area-field'], className)}>
      {label !== undefined && <FieldLabel htmlFor={id}>{label}</FieldLabel>}

      <div>
        <textarea
          id={id}
          ref={forwardedRef}
          name={name}
          className={styles['text-area-field__textarea']}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          rows={rows}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
}

export const TextAreaField = forwardRef(TextAreaFieldComponent);

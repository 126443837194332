import { z } from 'zod';

export interface RecurringType {
  interval: 'week' | 'month' | 'year';
  intervalCount: number;
}

export interface ProductType {
  lookupKey: string;
  amount: number;
  recurring: RecurringType;
}

export type GetProductsType = ProductType[];

const ProductSchema = z.object({
  lookup_key: z.string(),
  unit_amount: z.number(),
  recurring: z.object({
    interval: z.enum(['week', 'month', 'year']),
    interval_count: z.number(),
  }),
});

const ProductsResponseSchema = z.object({
  data: z.object({
    data: z.array(ProductSchema),
  }),
});

export async function getProducts(): Promise<GetProductsType> {
  const res = await fetch(
    `${import.meta.env.VITE_PRODUCTS_API_BASE_URL}/api/payment/products`
  );

  if (!res.ok) {
    throw new Error('Failed to fetch data');
  }

  const response = (await res.json()) as unknown;

  const parsedResponse = ProductsResponseSchema.safeParse(response);

  if (!parsedResponse.success) {
    throw new Error('Failed to parse data');
  }

  const { data: parsedData } = parsedResponse;

  return parsedData.data.data
    .map((product) => ({
      lookupKey: product.lookup_key,
      amount: product.unit_amount,
      recurring: {
        interval: product.recurring.interval,
        intervalCount: product.recurring.interval_count,
      },
    }))
    .sort((a, b) => (a.amount > b.amount ? 1 : -1));
}

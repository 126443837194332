import cn from 'clsx';
import type { ReactElement } from 'react';

import styles from './Container.module.scss';

interface ContainerProps {
  children: ReactElement | ReactElement[];
  className?: string;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
}

export function Container({
  children,
  className,
  maxWidth,
}: ContainerProps): ReactElement {
  return (
    <div
      className={cn(
        styles.container,
        styles[`container--max-w-${maxWidth}`],
        className
      )}
    >
      {children}
    </div>
  );
}

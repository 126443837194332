import { type ReactElement } from 'react';
import cn from 'clsx';

import logoIconImage from '@/assets/clonix-logo.png';
import { ReactComponent as LogoNameIcon } from '@/assets/logo-name.svg';

import styles from './Logo.module.scss';

export function Logo({
  size,
  withName = false,
  className,
}: {
  size: 'small' | 'large';
  withName?: boolean;
  className?: string;
}): ReactElement {
  return (
    <div className={cn(styles.logo, className)}>
      <img
        src={logoIconImage}
        width={294}
        height={106}
        className={cn(styles.logo__logo, styles[`logo__logo--size-${size}`])}
        alt="Logo"
      />

      {withName && <LogoNameIcon className={styles.logo__name} />}
    </div>
  );
}

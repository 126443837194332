import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { useAppDispatch } from './store';

import { auth } from '@/providers/firebase';
import { logOut, setUser } from '@/features/auth';

export const useAuth = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (user !== null && user.emailVerified) {
        dispatch(
          setUser({
            email: user.email,
            uid: user.uid,
            displayName: user.displayName,
            photoUrl: user.photoURL,
          })
        );
      } else {
        dispatch(logOut());
      }
    });

    return unsubscribe;
  }, [dispatch]);
};

'use client';

import { useState, type ReactElement, useEffect } from 'react';

import { Logo } from '@/components';
import cn from 'clsx';

import styles from './Header.module.scss';
import { useLocation } from 'react-router-dom';

export function Header(): ReactElement {
  const [isBuyRoute, setIsBuyRoute] = useState('false');
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/buy') {
      setIsBuyRoute('true');
    }
  }, [pathname]);

  return (
    <header
      className={cn(styles.header, {
        [styles['header--buy']]: isBuyRoute === 'true',
      })}
    >
      <Logo size="large" />
    </header>
  );
}

import type { ReactElement } from 'react';
import styles from './BuySection.module.scss';
import { Features } from '../Features/Features';
import { ChoosePlan } from '../ChoosePlan/ChoosePlan';
import type { GetProductsType } from '../../services/products';

export function BuySection(): ReactElement {
  const products: GetProductsType = [
    {
      lookupKey: 'prod_J0Z2X0Y0Z0Z0Z0',
      amount: 699,
      recurring: {
        interval: 'week',
        intervalCount: 7,
      },
    },
    {
      lookupKey: 'prod_J0Z2X0Y0Z0Z0Z1',
      amount: 2999,
      recurring: {
        interval: 'month',
        intervalCount: 1,
      },
    },
    {
      lookupKey: 'prod_J0Z2X0Y0Z0Z0Z4',
      amount: 29999,
      recurring: {
        interval: 'year',
        intervalCount: 1,
      },
    },
  ];

  return (
    <section className={styles.section}>
      <div className={styles['video-area']}>
        <video
          width="549"
          height="558"
          loop
          muted
          autoPlay
          className={styles['video-area__video']}
        >
          <source
            src="https://chatgptui.s3.eu-west-1.amazonaws.com/clonix-buy.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={styles.plans}>
        <div className={styles.plans__container}>
          <h1 className={styles.title}>Unlock Unlimited Access</h1>

          <div className={styles.features}>
            <Features />
          </div>

          <ChoosePlan products={products} />
        </div>
      </div>
    </section>
  );
}

import {
  type DOMAttributes,
  type FormEventHandler,
  type ReactElement,
  type ReactNode,
} from 'react';

export function Form({
  children,
  onSubmit,
  className,
}: {
  children: ReactNode;
  onSubmit: NonNullable<DOMAttributes<HTMLFormElement>['onSubmit']>;
  className?: string;
}): ReactElement {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      {children}
    </form>
  );
}

'use client';

import React, { type ReactElement, useState, useMemo } from 'react';

import { Button } from '@/components';

import {
  type ProductType,
  type GetProductsType,
  type RecurringType,
} from '../../services/products';
import { PlanItem } from './PlanItem/PlanItem';
import styles from './ChoosePlan.module.scss';

interface PlanType {
  lookupKey: string;
  recurring: RecurringType;
  price: string;
  adornment: {
    type: 'save' | 'none';
    value?: string;
  };
}

export function ChoosePlan({
  products,
}: {
  products: GetProductsType;
}): ReactElement {
  const [planLookupKey, setPlanLookupKey] = useState(products[0].lookupKey);

  const plans: PlanType[] = useMemo(() => {
    const priceFormatter = Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'usd',
      minimumFractionDigits: 0,
    });

    const discountFormatter = Intl.NumberFormat('en', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const getProductPricePerDay = (product: ProductType): number => {
      const { interval, intervalCount } = product.recurring;

      const daysPerInterval: Record<RecurringType['interval'], number> = {
        week: 7,
        month: 30,
        year: 365,
      };

      return product.amount / 100 / (daysPerInterval[interval] * intervalCount);
    };

    const getProductDiscountPercentage = ({
      cheapProduct,
      expensiveProduct,
    }: {
      cheapProduct: ProductType;
      expensiveProduct: ProductType;
    }): number => {
      const expensiveProductDailyPrice =
        getProductPricePerDay(expensiveProduct);
      const cheapProductDailyPrice = getProductPricePerDay(cheapProduct);

      const savePercentage =
        (expensiveProductDailyPrice - cheapProductDailyPrice) /
        expensiveProductDailyPrice;

      return savePercentage;
    };

    return products.map((product, index, array) => {
      const adornmentType = (() => {
        const showSaveAmount = index === array.length - 1;

        if (showSaveAmount) return 'save';
        return 'none';
      })();

      return {
        lookupKey: product.lookupKey,
        recurring: product.recurring,
        price: priceFormatter.format(product.amount / 100),
        adornment: {
          type: adornmentType,
          value:
            adornmentType === 'save'
              ? discountFormatter.format(
                  getProductDiscountPercentage({
                    cheapProduct: product,
                    expensiveProduct: array[0],
                  })
                )
              : undefined,
        },
      };
    });
  }, [products]);

  return (
    <form className={styles.plans}>
      <fieldset className={styles.plans__fieldset}>
        {plans.map(({ lookupKey, recurring, price, adornment }, index) => (
          <PlanItem
            key={index}
            lookupKey={lookupKey}
            recurring={recurring}
            price={price}
            adornment={adornment}
            checked={planLookupKey === lookupKey}
            onSelectPlan={() => {
              setPlanLookupKey(lookupKey);
            }}
          />
        ))}
      </fieldset>

      <Button
        size="large"
        variant="primary"
        type="link"
        className={styles.plans__button}
        href="/"
      >
        Continue
      </Button>
    </form>
  );
}

import cn from 'clsx';
import { type ReactElement, type ReactNode } from 'react';

import styles from './Section.module.scss';

export function Section({
  children,
  className,
  variant,
}: {
  children: ReactNode;
  className?: string;
  variant: 'spacious' | 'compact' | 'balanced' | 'hero';
}): ReactElement {
  return (
    <section
      className={cn(
        styles.section,
        styles[`section--variant-${variant}`],
        className
      )}
    >
      {children}
    </section>
  );
}

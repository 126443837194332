import { useState, type ReactElement } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
} from 'react-firebase-hooks/auth';

import { auth } from '@/providers/firebase';
import {
  Button,
  Form,
  InlineLink,
  PasswordField,
  TextField,
} from '@/components';

import { handlePromiseEvent } from '@/utils/handle-promise-event';
import { FormTitle } from '../../components/FormTitle/FormTitle';
import { AuthFormSeparator } from '../../components/AuthFormSeparator/AuthFormSeparator';
import { SocialLogin } from '../../components/SocialLogin/SocialLogin';
import styles from './SignUpForm.module.scss';
import { useSearchParams } from 'react-router-dom';

interface SignUpFormType {
  email: string;
  password: string;
}

export function SignUpForm(): ReactElement {
  const [createUserWithEmailAndPassword, , signUpLoading, signUpError] =
    useCreateUserWithEmailAndPassword(auth);
  const [
    sendEmailVerification,
    sendEmailVerificationLoading,
    sendEmailVerificationError,
  ] = useSendEmailVerification(auth);

  const [emailVerificationSent, setEmailVerificationSent] = useState(false);

  const [params] = useSearchParams();
  const initialEmail = params.get('email');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormType>({
    defaultValues: {
      email: initialEmail ?? '',
      password: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .nonempty("Email can't be empty.")
          .email('Email is invalid'),
        password: z
          .string()
          .nonempty("Password can't be empty")
          .min(6, 'Password needs to be at least 6 characters long.'),
      })
    ),
  });

  const handleSignUp: SubmitHandler<SignUpFormType> = async ({
    email,
    password,
  }) => {
    const credentials = await createUserWithEmailAndPassword(email, password);

    if (credentials !== undefined && !credentials.user.emailVerified) {
      const success = await sendEmailVerification();

      // Immediately make user sign-out, so they can login using their email and password.
      await auth.signOut();

      setEmailVerificationSent(success);
    }
  };

  return (
    <div className={styles['sign-up-form']}>
      <FormTitle>Create Your Account</FormTitle>

      {emailVerificationSent && (
        <p>Check your email for instructions to verify your email.</p>
      )}

      {!emailVerificationSent && (
        <Form
          className={styles.form}
          onSubmit={handlePromiseEvent(handleSubmit(handleSignUp))}
        >
          <TextField
            type="email"
            placeholder="Email"
            errorMessage={errors.email?.message}
            {...register('email')}
          />

          <PasswordField
            placeholder="Password"
            errorMessage={errors.password?.message}
            {...register('password')}
          />

          {signUpError !== undefined && (
            <p className={styles.error}>{signUpError.message}</p>
          )}

          {sendEmailVerificationError !== undefined && (
            <p className={styles.error}>{sendEmailVerificationError.message}</p>
          )}

          <Button
            variant="primary"
            loading={signUpLoading || sendEmailVerificationLoading}
            type="submit"
          >
            Continue
          </Button>
        </Form>
      )}

      <AuthFormSeparator />

      <SocialLogin />

      <div className={styles['login-info']}>
        <span>Do you have an account?</span>{' '}
        <InlineLink href="/login">Log in</InlineLink>
      </div>
    </div>
  );
}

import { Button, Heading, Text } from '@/components';
import { useState, type ReactElement } from 'react';

import styles from './OnboardingFourth.module.scss';
import { CheckboxGroup } from '../../components/CheckboxGroup/CheckboxGroup';
import { Circles } from '../../components/Circles/Circles';

const onboardingAnswers = [
  {
    label: 'Under 20',
    value: 'under-20',
  },
  {
    label: '20 - 29',
    value: '20-29',
  },
  {
    label: '30-39',
    value: '30-39',
  },
  {
    label: '40 - 49',
    value: '40-49',
  },
  {
    label: '50 - 59',
    value: '50-59',
  },
  {
    label: '60+',
    value: '60+',
  },
] as const;

export function OnboardingFourth(): ReactElement {
  const [answers, setAnswers] = useState<
    Array<(typeof onboardingAnswers)[number]['value']>
  >([]);

  return (
    <div className={styles.onboarding}>
      <Heading
        className={styles.title}
        as="h2"
        size={{
          initial: '6',
        }}
        weight="bold"
      >
        How old are you?
      </Heading>

      <Text
        className={styles.subtitle}
        as="p"
        size={{
          initial: '3',
        }}
        weight="medium"
      >
        We want to align our service with your needs and preferences.
      </Text>

      <div className={styles['question-content']}>
        <CheckboxGroup
          layout="wrap"
          value={answers}
          segments={onboardingAnswers}
          onChange={(places) => {
            setAnswers(places);
          }}
        />
      </div>

      <Circles active={3} />

      <Button
        type="link"
        href="/login"
        variant="primary"
        className={styles['next-button']}
      >
        Next
      </Button>
    </div>
  );
}

import { Icon } from '@/components';
import { faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import {
  useRef,
  type ReactElement,
  useState,
  type ChangeEventHandler,
  type MouseEventHandler,
} from 'react';
import cn from 'clsx';

import styles from './AddImage.module.scss';

export function AddImage({
  selectedFile,
  onSelectFile,
}: {
  selectedFile: File | null;
  onSelectFile: (file: File | null) => void;
}): ReactElement {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const hasSelectedFile = selectedFile !== null;

  const handleClick = (): void => {
    fileInputRef?.current?.click();
  };

  const removeSelectedFile: MouseEventHandler = (event): void => {
    event.stopPropagation();

    if (previewImage !== null) {
      URL.revokeObjectURL(previewImage);
      setPreviewImage(null);
    }
    onSelectFile(null);
  };

  const handleSelectFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files?.[0];

    if (file !== undefined) {
      onSelectFile(file);

      const preview = URL.createObjectURL(file);
      setPreviewImage(preview);
    }

    if (fileInputRef.current !== null) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div
      className={cn(styles['add-image'], {
        [styles['add-image--selected']]: hasSelectedFile,
      })}
    >
      <button
        onClick={handleClick}
        type="button"
        className={styles['add-image__button']}
      >
        <Icon
          icon={faPlus}
          className={styles['add-image__button-icon']}
          size="2x"
        />

        {previewImage !== null && hasSelectedFile && (
          <div className={styles['add-image__preview']}>
            <img
              src={previewImage}
              alt=""
              className={styles['add-image__preview-image']}
            />

            <button
              onClick={removeSelectedFile}
              type="button"
              className={styles['add-image__remove']}
            >
              <Icon
                icon={faTrashAlt}
                className={styles['add-image__remove-icon']}
                size="2x"
              />
            </button>
          </div>
        )}
      </button>

      <input
        ref={fileInputRef}
        onChange={handleSelectFile}
        type="file"
        accept="image/jpeg,image/png"
        hidden
      />

      <div className={styles['add-image__name']}>Your Image</div>
    </div>
  );
}

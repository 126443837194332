import { useId, type ReactElement } from 'react';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import cn from 'clsx';

import styles from './CheckboxGroup.module.scss';
import { SelectItem } from '../SelectItem/SelectItem';

export function CheckboxGroup<SegmentValue extends string>({
  layout,
  segments,
  value,
  onChange,
}: {
  layout: 'list' | 'wrap';
  value: SegmentValue[];
  segments: ReadonlyArray<{
    label: string;
    value: SegmentValue;
    icon?: IconDefinition;
  }>;
  onChange: (segment: SegmentValue[]) => void;
}): ReactElement {
  const groupName = useId();

  return (
    <fieldset
      className={cn(
        styles['checkbox-group'],
        styles[`checkbox-group--layout-${layout}`]
      )}
    >
      {segments.map((item) => (
        <SelectItem
          layout={layout}
          key={item.value}
          type="checkbox"
          groupName={groupName}
          icon={item.icon}
          checked={value.includes(item.value)}
          label={item.label}
          onChange={() => {
            if (value.includes(item.value)) {
              const valuesAfterRemove = value.filter(
                (valueItem) => valueItem !== item.value
              );

              onChange(valuesAfterRemove);
              return;
            }

            onChange([...value, item.value]);
          }}
        />
      ))}
    </fieldset>
  );
}

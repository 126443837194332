import { useState, type ReactElement } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import cn from 'clsx';
import { Button, Form } from '@/components';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import styles from './GenerateForm.module.scss';
import { AddImage } from './AddImage/AddImage';
import { ChooseVoiceStyle } from './ChooseVoiceStyle/ChooseVoiceStyle';
import { useGenerateMediaMutation } from '../../services/generate';
import { TextAreaField } from '../../components/TextAreaField/TextAreaField';
import { voiceStyles } from '../../data/voice-styles';
import { Results } from './Results/Results';
import { Generating } from './Generating/Generating';
import { SectionLabel } from '../../components/SectionLabel/SectionLabel';
import { ResultError } from './ResultError/ResultError';

interface GenerateFormType {
  content: string;
  style: string | File;
}

export function GenerateForm(): ReactElement {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<GenerateFormType>({
    defaultValues: {
      content: '',
      style: voiceStyles[0].id,
    },
    resolver: zodResolver(
      z.object({
        content: z.string().trim(),
        style: z.string().trim(),
      })
    ),
  });

  const [generateMedia, { isLoading, isError }] = useGenerateMediaMutation();

  const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

  const [result, setResult] = useState<{
    link: string;
    type: 'video' | 'audio';
  } | null>(null);

  const handleGenerate: SubmitHandler<GenerateFormType> = async ({
    content,
    style,
  }) => {
    // Reset results before generating new
    setResult(null);

    const formData = new FormData();

    formData.append('text', content);

    if (selectedAudioFile !== null && style === 'your-voice') {
      formData.append('audio', selectedAudioFile);
    } else {
      formData.append('user_id', style);
    }

    if (selectedImageFile !== null) {
      formData.append('image', selectedImageFile);
    }

    const result = await generateMedia(formData).unwrap();

    setResult({
      link: result.link,
      type: result.type,
    });
  };

  const content = watch('content');

  const disabled = isLoading || content === '';

  return (
    <div className={styles['generate-form']}>
      <Form
        className={styles.form}
        onSubmit={handlePromiseEvent(handleSubmit(handleGenerate))}
      >
        <div className={styles['generate-form__form']}>
          <div className={styles['generate-form__form-inner']}>
            <div className={styles['generate-form__textarea']}>
              <TextAreaField
                label="Your text"
                rows={5}
                maxLength={600}
                placeholder="Type here whatever you want, or just express yourself..."
                errorMessage={errors.content?.message}
                {...register('content')}
              />
            </div>

            <div className={styles['generate-form__options']}>
              <div>
                <SectionLabel>Add Image</SectionLabel>

                <AddImage
                  selectedFile={selectedImageFile}
                  onSelectFile={(file) => {
                    setSelectedImageFile(file);
                  }}
                />
              </div>

              <div className={styles['generate-form__options-divider']} />

              <div className={styles['generate-form__voice-styles']}>
                <SectionLabel
                  className={styles['generate-form__voice-styles-label']}
                >
                  Choose a voice style
                </SectionLabel>

                <ChooseVoiceStyle
                  {...register('style')}
                  selectedFile={selectedAudioFile}
                  onSelectFile={(file) => {
                    setSelectedAudioFile(file);
                    setValue('style', 'your-voice');
                  }}
                />
              </div>
            </div>

            <Button
              variant="primary"
              type="submit"
              className={styles['generate-form__submit']}
              disabled={disabled}
              size="small"
            >
              Generate
            </Button>
          </div>
        </div>
      </Form>

      <div
        className={cn(styles['generate-form__results'], {
          [styles['generate-form__results--shown']]:
            isLoading || result !== null || isError,
        })}
      >
        <SectionLabel>Results</SectionLabel>

        {isError && <ResultError />}

        {isLoading && <Generating />}

        {!isLoading && result !== null && (
          <Results resultLink={result.link} resultType={result.type} />
        )}
      </div>
    </div>
  );
}

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './fetch';

const isDevelopment = import.meta.env.DEV;

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
  tagTypes: [],
  keepUnusedDataFor: isDevelopment ? 0 : 10,
  refetchOnMountOrArgChange: isDevelopment,
});

import type { ReactElement } from 'react';
import Lottie from 'react-lottie-player';

import * as animationData from '@/assets/lotties/loading.json';

import styles from './Generating.module.scss';

export function Generating(): ReactElement {
  return (
    <div className={styles.generating}>
      <div>
        <Lottie
          loop
          play
          animationData={animationData}
          className={styles.generating__lottie}
        />
      </div>

      <div className={styles.generating__text}>Generating</div>
    </div>
  );
}

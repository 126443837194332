/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, type ReactElement } from 'react';

import styles from './Onboarding.module.scss';
import { Button, Container, Heading, Icon, Section, Text } from '@/components';

import elonMuskSource from '@/assets/elon-musk.png';
import snoopDoggSource from '@/assets/snoop-dog.png';
import cemOrtabasSource from '@/assets/cem-ortabas.png';
import morganFreemanSource from '@/assets/morgan-freeman.png';
import obamaSource from '@/assets/obama.png';
import denizTunaSource from '@/assets/deniz-tuna.png';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { Circles } from '../../components/Circles/Circles';

interface examplesProps {
  avatar: string;
  title: string;
  subtitle: string;
  audioUrl: string;
}

const examples: examplesProps[] = [
  {
    avatar: elonMuskSource,
    title: 'Elon Musk',
    subtitle: 'Business Magnate',
    audioUrl:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Elon+Musk.mp3',
  },
  {
    avatar: snoopDoggSource,
    title: 'Snoop Dogg',
    subtitle: 'Rapper',
    audioUrl:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Snoop+Dogg+Final.mp3',
  },
  {
    avatar: cemOrtabasSource,
    title: 'Cem Ortabaş',
    subtitle: 'Actor',
    audioUrl:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Cem+Ortabas.mp3',
  },
  {
    avatar: morganFreemanSource,
    title: 'Morgan Freeman',
    subtitle: 'Writer',
    audioUrl:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Morgan+Freeman.mp3',
  },
  {
    avatar: obamaSource,
    title: 'Obama',
    subtitle: 'President',
    audioUrl:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/ElevenLabs_2023-11-03T18_55_03_Obama_ivc_s50_sb75_se0_b_m2.mp3',
  },
  {
    avatar: denizTunaSource,
    title: 'Deniz Tuna',
    subtitle: 'Actor',
    audioUrl:
      'https://landingx.s3.eu-west-1.amazonaws.com/clonix/Deniz+Tuna.mp3',
  },
];

export function Onboarding(): ReactElement {
  const audioRef = useRef(new Audio());

  const playAudio = (audioUrl: string): void => {
    audioRef.current.src = audioUrl;
    void audioRef.current.play();
  };

  return (
    <div className={styles.onboarding}>
      <Section variant="spacious">
        <Container maxWidth="2xl" className={styles['onboarding-container']}>
          <div className={styles['example-cards']}>
            {examples.map((example, i) => (
              <div className={styles.card} key={i}>
                <img
                  className={styles.card__avatar}
                  src={example.avatar}
                  alt={example.title}
                />
                <div className={styles.card__content}>
                  <Heading
                    className={styles.card__title}
                    as="h2"
                    size={{
                      initial: '3',
                    }}
                    weight="bold"
                  >
                    {example.title}
                  </Heading>

                  <Text
                    className={styles.card__subtitle}
                    as="p"
                    size={{
                      initial: '3',
                    }}
                    weight="medium"
                  >
                    {example.subtitle}
                  </Text>

                  <Button
                    type="button"
                    variant="primary"
                    className={styles.card__button}
                    onClick={() => {
                      playAudio(example.audioUrl);
                    }}
                  >
                    <Icon icon={faPlay} />
                  </Button>
                </div>

                <audio ref={audioRef} controls className={styles.card__audio}>
                  <source src={example.audioUrl} type="audio/mpeg " />
                  Your browser does not support the audio element.
                </audio>
              </div>
            ))}
          </div>

          <Heading
            className={styles.description}
            as="h2"
            size={{
              initial: '6',
            }}
            weight="semi-bold"
          >
            Over 60+ natural, human-like voices and 15 languages
          </Heading>

          <Circles active={0} />

          <Button
            type="link"
            href="/onboarding?step=2"
            variant="primary"
            className={styles['next-button']}
          >
            Next
          </Button>
        </Container>
      </Section>
    </div>
  );
}

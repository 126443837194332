import { type ReactElement } from 'react';
import { Onboarding } from '../../containers/Onboarding/Onboarding';
import { useSearchParams } from 'react-router-dom';
import { OnboardingThird } from '../../containers/OnboardingThird/OnboardingThird';
import { OnboardingSecond } from '../../containers/OnboardingSecond/OnboardingSecond';
import { OnboardingFourth } from '../../containers/OnboardingFourth/OnboardingFourth';

export function OnboardingRoute(): ReactElement {
  const [params] = useSearchParams();
  const step = params.get('step');

  if (step === '2') {
    return <OnboardingSecond />;
  }

  if (step === '3') {
    return <OnboardingThird />;
  }

  if (step === '4') {
    return <OnboardingFourth />;
  }

  return <Onboarding />;
}

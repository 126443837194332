import { type ReactElement } from 'react';
import cn from 'clsx';

import { Badge } from './Badge/Badge';
import { type RecurringType } from '../../../services/products';
import styles from './PlanItem.module.scss';

export function PlanItem({
  lookupKey,
  price,
  adornment,
  checked,
  onSelectPlan,
  recurring,
}: {
  lookupKey: string;
  price: string;
  adornment: {
    type: 'save' | 'none';
    value?: string;
  };
  checked: boolean;
  onSelectPlan: () => void;
  recurring: RecurringType;
}): ReactElement {
  const getProductTitle = ({ interval }: RecurringType): string => {
    const periods: Record<RecurringType['interval'], string> = {
      week: 'week',
      month: 'month',
      year: 'year',
    };

    return `${price}/${periods[interval]}`;
  };

  const getProductDescription = ({ interval }: RecurringType): string => {
    const periods: Record<RecurringType['interval'], string> = {
      week: 'Weekly subscription, auto renewal',
      month: 'Monthly subscription, auto renewal',
      year: 'Yearly subscription, auto renewal',
    };

    return periods[interval];
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={cn(styles.plan, {
        [styles['plan--checked']]: checked,
      })}
    >
      <input
        type="radio"
        name="lookup_key"
        value={lookupKey}
        checked={checked}
        onChange={onSelectPlan}
        required
        className={styles.plan__input}
      />

      <div className={styles.plan__content}>
        <div className={styles.title}>
          <div className={styles.title__price}>
            {getProductTitle(recurring)}
          </div>

          <div className={styles.title__description}>
            {getProductDescription(recurring)}
          </div>
        </div>

        <div className={styles.badge}>
          {adornment.type === 'save' && (
            <Badge type="save">
              Save <br />
              {adornment.value}
            </Badge>
          )}
        </div>
      </div>
    </label>
  );
}

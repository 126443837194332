import { type ReactElement } from 'react';
import cn from 'clsx';

import styles from './Spinner.module.scss';

export function Spinner({ active = false }: { active: boolean }): ReactElement {
  return (
    <div
      className={cn(styles.spinner, {
        [styles['spinner--active']]: active,
      })}
    >
      <span />
      <span />
      <span />
    </div>
  );
}

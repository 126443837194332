import type { ReactElement } from 'react';

import {
  type CustomTypographyProps,
  Typography,
} from '../Typography/Typography';

export function Heading(
  props: CustomTypographyProps<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>
): ReactElement {
  return <Typography {...props} />;
}

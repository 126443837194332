import { type ReactElement } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';

import { auth } from '@/providers/firebase';
import {
  Button,
  Form,
  TextField,
  InlineLink,
  PasswordField,
} from '@/components';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import styles from './LoginForm.module.scss';
import { FormTitle } from '../../components/FormTitle/FormTitle';
import { AuthFormSeparator } from '../../components/AuthFormSeparator/AuthFormSeparator';
import { SocialLogin } from '../../components/SocialLogin/SocialLogin';

interface LoginFormType {
  email: string;
  password: string;
}

// TODO: Refactor this to EmailLogin.
export function LoginForm(): ReactElement {
  const [signInWithEmailAndPassword, , loading, error] =
    useSignInWithEmailAndPassword(auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .nonempty("Email can't be empty.")
          .email('Email is invalid'),
        password: z
          .string()
          .nonempty("Password can't be empty")
          .min(6, 'Password needs to be at least 6 characters long.'),
      })
    ),
  });

  const handleLogin: SubmitHandler<LoginFormType> = async ({
    email,
    password,
  }) => {
    await signInWithEmailAndPassword(email, password);

    // Force sign-out if email is not verified.
    if (auth.currentUser?.emailVerified === false) {
      await auth.signOut();

      // TODO: Show a custom error if email is not verified.
    }
  };

  return (
    <section aria-label="Login form" className={styles['login-form']}>
      <FormTitle>Log in to CloniX!</FormTitle>

      <Form
        className={styles.form}
        onSubmit={handlePromiseEvent(handleSubmit(handleLogin))}
      >
        <TextField
          type="email"
          placeholder="Email"
          errorMessage={errors.email?.message}
          {...register('email')}
        />

        <PasswordField
          placeholder="Password"
          errorMessage={errors.password?.message}
          {...register('password')}
        />

        <div className={styles['forgot-password']}>
          <InlineLink href="/forgot-password">Forgot Password</InlineLink>
        </div>

        {/* TODO: We need to handle error messages. */}
        {error !== undefined && <p className={styles.error}>{error.message}</p>}

        <Button variant="primary" loading={loading} type="submit">
          Continue
        </Button>
      </Form>

      <AuthFormSeparator />

      <SocialLogin />

      <div className={styles['sign-up-info']}>
        <span>Don&apos;t have an account?</span>{' '}
        <InlineLink href="/register">Sign up</InlineLink>
      </div>
    </section>
  );
}

import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import styles from './InlineLink.module.scss';

export function InlineLink({
  href,
  children,
}: {
  href: string;
  children: string;
}): ReactElement {
  return (
    <Link to={href} className={styles['inline-link']}>
      {children}
    </Link>
  );
}

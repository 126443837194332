import { Button, Heading } from '@/components';
import { useState, type ReactElement } from 'react';

import styles from './OnboardingSecond.module.scss';
import { CheckboxGroup } from '../../components/CheckboxGroup/CheckboxGroup';
import { Circles } from '../../components/Circles/Circles';

const onboardingAnswers = [
  {
    label: 'Help with Work',
    value: 'help-with-work',
  },
  {
    label: 'Read More',
    value: 'read-more',
  },
  {
    label: 'To Create Content',
    value: 'to-create-content',
  },
  {
    label: 'Reduce Stress on Eyes',
    value: 'reduce-stress-on-eyes',
  },
  {
    label: 'Get Throught School',
    value: 'get-throught-school',
  },
  {
    label: 'Reading Disability',
    value: 'reading-disability',
  },
  {
    label: 'Boost Productivity',
    value: 'boost-productivity',
  },
] as const;

export function OnboardingSecond(): ReactElement {
  const [answers, setAnswers] = useState<
    Array<(typeof onboardingAnswers)[number]['value']>
  >([]);

  return (
    <div className={styles.onboarding}>
      <Heading
        className={styles.title}
        as="h2"
        size={{
          initial: '6',
        }}
        weight="bold"
      >
        What do you want to achieve using WinnerTeam!
      </Heading>

      <div className={styles['question-content']}>
        <CheckboxGroup
          layout="wrap"
          value={answers}
          segments={onboardingAnswers}
          onChange={(places) => {
            setAnswers(places);
          }}
        />
      </div>

      <Circles active={1} />

      <Button
        type="link"
        href="/onboarding?step=3"
        variant="primary"
        className={styles['next-button']}
      >
        Next
      </Button>
    </div>
  );
}

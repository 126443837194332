import {
  type ChangeEventHandler,
  type FocusEventHandler,
  type ForwardedRef,
  type ReactElement,
  forwardRef,
  useId,
} from 'react';
import cn from 'clsx';

import { ErrorMessage } from './ErrorMessage/ErrorMessage';
import styles from './TextField.module.scss';

interface TextFieldProps {
  type: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'search';
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder: string;
  value?: string;
  className?: string;
  name?: string;
  errorMessage?: string;
  endAdornment?: ReactElement;
}

function TextFieldComponent(
  {
    name,
    type,
    value,
    onChange,
    placeholder,
    className,
    onBlur,
    errorMessage,
    endAdornment,
  }: TextFieldProps,
  forwardedRef: ForwardedRef<HTMLInputElement>
): ReactElement {
  const id = useId();

  const hasError = errorMessage !== undefined;

  return (
    <div
      className={cn(
        styles['text-field'],
        {
          [styles['text-field--error']]: hasError,
        },
        className
      )}
    >
      <div className={styles['text-field__inner']}>
        <input
          id={id}
          ref={forwardedRef}
          name={name}
          className={styles['text-field__input']}
          placeholder=" "
          value={value}
          onChange={onChange}
          type={type}
          onBlur={onBlur}
        />

        <label htmlFor={id} className={styles['text-field__label']}>
          {placeholder}
        </label>

        {endAdornment !== undefined && (
          <div className={styles['text-field__end-adornment']}>
            {endAdornment}
          </div>
        )}

        <div className={styles['text-field__outline']}></div>
      </div>

      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}

export const TextField = forwardRef(TextFieldComponent);

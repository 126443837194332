import {
  forwardRef,
  type ChangeEventHandler,
  type FocusEventHandler,
  type ReactElement,
  type ForwardedRef,
} from 'react';

import styles from './ChooseVoiceStyle.module.scss';
import { VoiceStyleItem } from './VoiceStyleItem/VoiceStyleItem';
import { AddYourVoice } from './AddYourVoice/AddYourVoice';

import { voiceStyles } from '../../../data/voice-styles';

function ChooseVoiceStyleComponent(
  {
    onChange,
    onBlur,
    name,
    selectedFile,
    onSelectFile,
  }: {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    selectedFile: File | null;
    onSelectFile: (file: File) => void;
    name: string;
  },
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  return (
    <fieldset className={styles['voice-style-list']}>
      <AddYourVoice
        selectedFile={selectedFile}
        onSelectFile={onSelectFile}
        inputRef={ref}
        onChange={onChange}
        onBlur={onBlur}
        groupName={name}
      />

      {voiceStyles.map((item, index) => {
        const { id, image, voice } = item;

        return (
          <VoiceStyleItem
            inputRef={ref}
            onChange={onChange}
            onBlur={onBlur}
            key={index}
            groupName={name}
            id={id}
            name={item.name}
            image={image}
            voice={voice}
          />
        );
      })}
    </fieldset>
  );
}

export const ChooseVoiceStyle = forwardRef(ChooseVoiceStyleComponent);
